<template>
  <div class="fr-event-detail" :class="{ 'screen-shot': isPrintScreen }">
    <div class="wrap-snapshot-map">
      <div class="snapshot">
        <EventSnapshot :event="event" type="fr" :enableZoomIn="false" />
      </div>
      <div class="map">
        <img v-if="event.latitude === 0 || event.longitude === 0" src="@/assets/icons/no-gps.svg" alt="">
        <EventMap v-else :event="event" type="fr" :centerShift="true" />
      </div>
    </div>
    <div class="wrap-photo-info">
      <div class="photo">
        <FrEventPhoto :event="event" :zoomFactor="zoomFactor" />
      </div>
      <div class="result">
        <FrEventMatch 
          v-if="event.triggered.length > 0" 
          :event="event" 
          :zoomFactor="zoomFactor"
          :isPrintScreen="isPrintScreen"
        />
      </div>
      <div class="info">
        <EventInfoFr :event="event" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrEventDetail",
  components: {
    EventSnapshot: () => import('@/components/Base/EventSnapshot.vue'), 
    EventMap: () => import('@/components/Base/EventMap.vue'), 
    FrEventPhoto: () => import('@/components/Base/FrEventPhoto.vue'), 
    FrEventMatch: () => import('@/components/Base/FrEventMatch.vue'), 
    EventInfoFr: () => import('@/components/Base/EventInfoFr.vue'),
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    isPrintScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zoomFactor: 1.0,
    };
  },
  mounted() {
    this.zoomFactor = window.innerWidth / 1366.0
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.zoomFactor = window.innerWidth / 1366.0
    }
  }  

}
</script>

<style lang="scss" scoped>
.fr-event-detail {
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 14px;
  
  &.screen-shot {
    height: auto;
    .wrap-snapshot-map {
      width: 600px;
      
      .snapshot {
        width: 560px;
        height: 360px;
      }

      .map {
        width: 560px;
        height: 360px;
      }
    }

    .wrap-photo-info {
      width: 600px;
    }
  }
}

.wrap-snapshot-map {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 14px;

  .map {
    width: 100%;
    height: 43%;
    background: #ffffff1a;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30%;
    }
  }
}

.wrap-photo-info {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .photo {
    width: 100%;
  }

  .result {
    width: 100%;
  }

  .info {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
  }
}
</style>